import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import CustomerForm from './CustomerForm.jsx';
import CustomerFormNeto from './CustomerFormNeto.jsx';
import CustomerFormZoho from './CustomerFormZoho.jsx';

export default function CustomerFormModal({ getMethods }) {

    const [ show, setShow ] = useState(false);

    useEffect(() => {
        getMethods({
            open: () => {
              setShow(true);
            }
        });
    }, []);

    const handleClose = (netoCustomer) => {

      if (netoCustomer) {
        window.OrderForm.upsertCustomer(netoCustomer);
      }

      setShow(false);
    };

    let CustomerFormComponent = CustomerFormNeto;
    if ( typeof(__KEAP_ENV__) !== 'undefined' && __KEAP_ENV__ ) {
        CustomerFormComponent = CustomerForm;
    }

    if ( typeof(__ZOHO_ENV__) !== 'undefined' && __ZOHO_ENV__ ) {
        CustomerFormComponent = CustomerFormZoho;
    }

    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerFormComponent cb={handleClose} />
          </Modal.Body>
        </Modal>
      </>
    );
};