import React, { useEffect } from 'react';

import Spinner from 'react-bootstrap/Spinner';
import { Typeahead } from 'react-bootstrap-typeahead';
import Highlighter from "react-highlight-words";

import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function CustomerSearch({ onSelect, customers, enableSearch }) {
    useEffect(() => {
        if ( customers && customers.length === 1 ) {
            onSelect(customers[0]);
        }
    }, [customers])

    return (
        <>
            {
            (customers.length === 1 &&
                <>
                    <div>
                        {customers[0].Email}
                    </div>

                    <div style={{ color: '#666', marginBottom: 12 }}>
                        <small>
                            <div>
                                {customers[0].Name} {customers[0].Company && `(${customers[0].Company})`}
                            </div>

                            <div>{customers[0].Address}</div>
                        </small>
                    </div>
                </>
            )
            ||
            (customers.length > 0 &&
                <Typeahead
                    id="customer-search"
                    onChange={_ => onSelect(_[0])}
                    options={customers}
                    placeholder="Find customers..."
                    labelKey="Email"
                    filterBy={(option, props) => {
                        if (enableSearch) {
                            const search = [option.Name, option.Email, option.Company].join(' ').toLowerCase();
                            const tokens = props.text.toLowerCase().split(' ');
                            for(let i = 0; i < tokens.length; i++) {
                                if (search.indexOf(tokens[i]) === -1) {
                                    return false;
                                }
                            }
                            return true;
                        }

                        const search = option.Email.toLowerCase();
                        const token = props.text.toLowerCase().trim();
                        return token && search === props.text.toLowerCase().trim();
                    }}
                    renderMenuItemChildren={(option, { text }, index) => <>
                        <div>
                            <Highlighter
                                highlightClassName='rbt-highlight-text'
                                searchWords={text.split(' ')}
                                autoEscape={true}
                                textToHighlight={option.Email}
                                />
                        </div>

                        <div style={{ color: '#666', marginBottom: 12 }}>
                            <small>
                                <div>
                                    <Highlighter
                                        highlightClassName='rbt-highlight-text'
                                        searchWords={text.split(' ')}
                                        autoEscape={true}
                                        textToHighlight={option.Name}
                                        />

                                    {option.Company && <> (<Highlighter
                                        highlightClassName='rbt-highlight-text'
                                        searchWords={text.split(' ')}
                                        autoEscape={true}
                                        textToHighlight={option.Company}
                                        />)</>
                                    }
                                </div>

                                <div>{option.Address}</div>
                            </small>
                        </div>
                        </>}
                    />
            )
            ||
            <div><Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner></div>
            }
        </>
    );
};