import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { get } from 'jquery';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomerSearch from './CustomerSearch.jsx';
import { canSearchCustomerDetails, shouldSeeDealerFields } from '../private/permissions.js';

const expectedCustomers = __NUM_CUSTOMERS__ || 120000;
const pagesize = 5000;

const states = __KEAP_ENV__ === 'SWAU' ? {
  'AU-ACT': 'Australian Capital Territory',
  'AU-NSW': 'New South Wales',
  'AU-NT': 'Northern Territory',
  'AU-QLD': 'Queensland',
  'AU-SA': 'South Australia',
  'AU-TAS': 'Tasmania',
  'AU-VIC': 'Victoria',
  'AU-WA': 'Western Australia'
} : {
  'NZ-NTL': 'Northland',
  'NZ-AUK': 'Auckland',
  'NZ-WKO': 'Waikato',
  'NZ-BOP': 'Bay of Plenty',
  'NZ-GIS': 'Gisborne',
  'NZ-HKB': 'Hawke\'s Bay',
  'NZ-TKI': 'Taranaki',
  'NZ-MWT': 'Manawatū-Whanganui',
  'NZ-WGN': 'Wellington',
  'NZ-TAS': 'Tasman',
  'NZ-NSN': 'Nelson',
  'NZ-MBH': 'Marlborough',
  'NZ-WTC': 'West Coast',
  'NZ-CAN': 'Canterbury',
  'NZ-OTA': 'Otago',
  'NZ-STL': 'Southland',
}

const getOptions = (field) => {

  if (field === 'states') {
    return Object.entries(states).map(([key, state]) => ({
      id: key,
      label: state
    }));
  }
};

const formatCustomer = _ => ({
  'Username': _.Username,
  'Email': _.EmailAddress,
  'Name': [_.BillingAddress.BillFirstName, _.BillingAddress.BillLastName].filter(_ => _).join(' '),
  'Company': _.BillingAddress.BillCompany,
  'SalesType': _.UserCustom2,
  'Address': [
    _.BillingAddress.BillStreetLine1,
    _.BillingAddress.BillStreetLine2,
    _.BillingAddress.BillCity,
    _.BillingAddress.BillState,
    _.BillingAddress.BillCountry,
  ].filter(_ => _).join(', ')
});

export default function OrderForm({ getMethods }) {

  const [cb, setCb] = useState({ cb: false, cancel: false });
  //const [ cb, setCb ] = useState({ cb: _ => _, cancel: _=>_ });

  const defaultState = {
    type: "order",
    dealerCustomerName: "",
    dealerPO: "",
    email: "",
    dateRequired: "",
    salesType: "",
    shippingAddress: {}
  };

  const [state, setState] = useState(defaultState);
  const [order, setOrder] = useState([]);
  const [user, setUser] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [salesTypes, setSalesTypes] = useState([]);

  // Determines if we should see Dealer PO and Dealer Name fields
  const showDealerFields = _ => shouldSeeDealerFields(user.UserRole);

  // This feature is fully disabled. Allows user to enter a custom delivery address on the order form
  const shouldShowDeliveryAddress = _ => false;

  const show = !!cb.cb;

  useEffect(() => {
    getMethods({
      open: ({ successCallback, cancelCallback, type, products, user }) => {
        setState({
          ...defaultState,
          type,
          salesType: user.Territory || ""
        });
        setOrder(products);
        setCb({ cb: successCallback, cancel: cancelCallback });
        setUser(user);
      },
      upsertCustomer: netoCustomer => {
        setCustomers(customers =>[
          ...(customers ? customers.filter(_ => _['Email'] !== netoCustomer['EmailAddress']) : []),
          formatCustomer(netoCustomer)
        ]);
      }
    });

    // Fetch customers - has to be on the outside of the modal so it's always there
    get_customers();
    async function get_customers() {

      const maxPages = expectedCustomers / pagesize;

      const allCustomerPages = [];
      for (let page = 0; page < maxPages; page++) {
        allCustomerPages.push(get(`api/customers?page=${page}&per_page=${pagesize}`));
      }

      const allCustomers = await Promise.all(allCustomerPages);
      const _customers = allCustomers.flat();

      if (!_customers) {
        return;
      }

      setCustomers(_customers.map(formatCustomer));
    }

    get_sales_types();
    async function get_sales_types() {
      const sales_types = await get('/api/salestypes');

      setSalesTypes(Array.from(sales_types).filter(_ => _['Code']).map(_ => ({
        code: _['Code'].trim(),
        description: (_['Description'] || '').trim()
      })));

      if (sales_types.length === 1) {
        setState({
          ...state,
          salesType: sales_types[0]['Code']
        });

        defaultState.salesType = sales_types[0]['Code'];
      }
    }
  }, []);

  const handleClose = () => { cb.cancel(); setCb(false) };
  const handleCb = _ => {
    cb.cb(state, order);
    setCb(false);
    setState(defaultState);
    setOrder([]);
  };



  const { type } = state;
  const typeStr = type.charAt(0).toUpperCase() + type.slice(1);
  const shippingComplete = () => {
    if (!shouldShowDeliveryAddress()) {
      return true
    }

    if (!('firstName' in state.shippingAddress)) {
      return false
    }
    if (!('lastName' in state.shippingAddress)) {
      return false
    }
    if (!('phone' in state.shippingAddress)) {
      return false
    }
    if (!('street1' in state.shippingAddress)) {
      return false
    }
    if (!('city' in state.shippingAddress)) {
      return false
    }
    if (!('province' in state.shippingAddress)) {
      return false
    }
    if (!('postcode' in state.shippingAddress)) {
      return false
    }
    return true
  }
  const isValid = state.email && state.salesType && shippingComplete();

  const renderSalesTypes = salesTypes
    .filter(salesType => {

      let customerSalesType = false;
      if (selectedCustomer && selectedCustomer['SalesType']) {
        customerSalesType = selectedCustomer['SalesType'];
      } else if (user.Territory) {
        customerSalesType = user.Territory;
      }

      // If no customer selected. Show all.
      if (!customerSalesType) {
        return true;
      }

      // If customer is dealer, only show that option.
      if (customerSalesType.substr(-1) === 'W') {
        return salesType['code'] === customerSalesType;
      }

      // If customer is retail, only show retail options
      if (customerSalesType.substr(-1) === 'R') {
        return salesType['code'].substr(-1) === 'R';
      }

      // Show all options otherwise
      return true;
    })
    .sort((a, b) => a['code'].localeCompare(b['code']));

  if (renderSalesTypes.length === 1 && state.salesType !== renderSalesTypes[0]['code']) {
    setState({ ...state, salesType: renderSalesTypes[0]['code'] });
  }

  const now = new Date();
  const offset = now.getTimezoneOffset()
  const today = new Date(now.getTime() - (offset*60*1000))
  const date = today.toISOString().split('T')[0]

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create {typeStr}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Customer:</Form.Label>
              <CustomerSearch
                onSelect={user => {
                  setState({ ...state, email: user ? user.Email : '' });
                  setSelectedCustomer(user);
                }}
                customers={customers}
                enableSearch={canSearchCustomerDetails(user.UserRole)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Estimated Delivery Date:</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setState({ ...state, dateRequired: e.target.value })}
                min={date}
                />
            </Form.Group>

            {
              showDealerFields() && <>
                <Form.Group>
                  <Form.Label>Dealer Customer Name:</Form.Label>
                  <Form.Control type="text" onChange={(e) => setState({ ...state, dealerCustomerName: e.target.value })} />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Dealer PO:</Form.Label>
                  <Form.Control type="text" onChange={(e) => setState({ ...state, dealerPO: e.target.value })} />
                </Form.Group>
              </>
            }

            {
              shouldShowDeliveryAddress() &&
              <>
                <Form.Group>
                  <Form.Label>Delivery First Name:</Form.Label>
                  <Form.Control required type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      firstName: e.target.value
                    }
                  })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Last Name:</Form.Label>
                  <Form.Control required type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      lastName: e.target.value
                    }
                  })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Company:</Form.Label>
                  <Form.Control required type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      company: e.target.value
                    }
                  })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Phone:</Form.Label>
                  <Form.Control required type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      phone: e.target.value
                    }
                  })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Street:</Form.Label>
                  <Form.Control required type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      street1: e.target.value
                    }
                  })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Unit/Apartment Number:</Form.Label>
                  <Form.Control type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      street2: e.target.value
                    }
                  })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery City:</Form.Label>
                  <Form.Control required type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      city: e.target.value
                    }
                  })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Province:</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="delivery_province_country"
                    onChange={e => {
                      const country = e.target.value.split("-")[0]
                      const province = e.target.value.split("-")[1]
                      setState({
                        ...state,
                        shippingAddress: {
                          ...state['shippingAddress'],
                          province,
                          country
                        }
                      })
                    }}
                  >
                    <option value=""></option>
                    {getOptions('states').map(option =>
                      <option value={option.id} key={option.id}>{option.label}</option>
                    )
                    }
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Postal Code:</Form.Label>
                  <Form.Control required type="text" onChange={(e) => setState({
                    ...state,
                    shippingAddress: {
                      ...state['shippingAddress'],
                      postcode: e.target.value
                    }
                  })} />
                </Form.Group>
              </>
            }

            <Form.Group>
              <Form.Label>Territory:</Form.Label>
              <Form.Control required as="select" onChange={(e) => setState({ ...state, salesType: e.target.value })} value={state.salesType}>
                {renderSalesTypes.length > 1 && <option value=""></option>}
                {renderSalesTypes.map(_ => <option value={_['code']} key={'salesType-' + _['code']}>{_['code']} - {_['description']}</option>)}
              </Form.Control>
            </Form.Group>

            <Table bordered hover>
              <thead>
                <tr>
                  <th>SKU/SN</th>
                  <th>Name</th>
                  <th>QTY</th>
                </tr>
              </thead>
              <tbody>
                {order.map((product, idx) => <React.Fragment key={`orderline-${idx}`}>
                  <tr>
                    <td>{product.sku}</td>
                    <td style={{ width: '100%' }}>{product.name}</td>
                    <td style={{ minWidth: 100 }}>{product.enableQty ? <Form.Group>
                      <Form.Control defaultValue={1} type="number" onChange={(e) => {
                        order[idx].qty = parseInt(e.target.value);
                        setOrder(order);
                      }} />
                    </Form.Group> : 1}</td>
                  </tr>
                </React.Fragment>)}
              </tbody>
            </Table>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-button" style={isValid ? { display: 'none' } : {}}>
                {
                  (!state.email && !state.salesType && "Please select a customer and territory first.")
                  ||
                  (!state.email && "Please select a customer first.")
                  ||
                  (!state.salesType && "Please select a territory first.")
                }
              </Tooltip>
            }
          >
            <Button variant="primary" onClick={handleCb} disabled={!isValid}>
              Create {typeStr}
            </Button>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
    </>
  );
};