import React from 'react';
import ReactDOM from 'react-dom';
import CustomerFormModal from './CustomerFormModal.jsx';
import OrderForm from './OrderForm.jsx';

if ( document.getElementById('components') ) {
    ReactDOM.render(<>
        <CustomerFormModal getMethods={methods => window.CustomerForm = methods} />
        <OrderForm getMethods={methods => window.OrderForm = methods} />
    </>, document.getElementById('components'));
}