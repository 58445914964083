'use strict';

import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { get, ajax } from 'jquery';
import {default as getDescendantProp} from 'lodash.get';
import {default as setDescendantProp} from 'lodash.set';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useForm } from 'react-hook-form';

const ucwords = str => str.toLowerCase().replace(/(?:(^.{1})|\ [a-z]{1})/g, function(a){return a.toUpperCase();})
const getTagLabel = (tag, field)  => {
    let label = tag.name.split(field.split, 2);
    label = label[1] ? label[1]: label[0];
    return ucwords(label.replaceAll('-', ' ').trim());
};

//https://reactgo.com/removeduplicateobjects/
const getUnique = (arr, comp) => {

    // store the comparison  values in array
    const unique = arr.map(e => e[comp])

        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
}

// https://community.keap.com/t/invalid-country-code-and-region-what-is-valid/13354/10
const states = __KEAP_ENV__ === 'SWAU' ? {
    'AU-ACT': 'Australian Capital Territory',
    'AU-NSW': 'New South Wales',
    'AU-NT': 'Northern Territory',
    'AU-QLD': 'Queensland',
    'AU-SA': 'South Australia',
    'AU-TAS': 'Tasmania',
    'AU-VIC': 'Victoria',
    'AU-WA': 'Western Australia'
} : {
    'NZ-NTL':  'Northland',
    'NZ-AUK':  'Auckland',
    'NZ-WKO':  'Waikato',
    'NZ-BOP':  'Bay of Plenty',
    'NZ-GIS':  'Gisborne',
    'NZ-HKB':  'Hawke\'s Bay',
    'NZ-TKI':  'Taranaki',
    'NZ-MWT':  'Manawatū-Whanganui',
    'NZ-WGN':  'Wellington',
    'NZ-TAS':  'Tasman',
    'NZ-NSN':  'Nelson',
    'NZ-MBH':  'Marlborough',
    'NZ-WTC':  'West Coast',
    'NZ-CAN':  'Canterbury',
    'NZ-OTA':  'Otago',
    'NZ-STL':  'Southland',
}

// Custom JSON POST function for jQuery. jQuery turns body into URL params automatically.
const post = function(url, body) {
    return ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(body),
        contentType: "application/json",
        dataType: 'json'
    });
}

const phone_types = [
    'Work', 'Home', 'Mobile', 'Other'
];

const fields = {
    'opt_in': {
        type: 'checkbox',
        label: 'I have permission to send marketing to this address',
        source: 'contact',
        id: 'opt_in_reason',
        value: 'Contact gave explicit permission.'
    },
    'owner': {
        type: 'user',
        label: 'Owner *',
        source: 'contact',
        id: 'owner_id',
        required: true
    },
    'sales-lead-from': {
        type: 'select',
        label: 'Sales Lead From *',
        source: 'custom_field',
        id: 'Sales Lead From',
        required: true
    },
    'contact-first-name': {
        type: 'text',
        label: 'Contact First Name *',
        source: 'contact',
        id: 'given_name',
        required: true,
        neto: 'BillingAddress.BillFirstName'
    },
    'contact-last-name': {
        type: 'text',
        label: 'Contact Last Name',
        source: 'contact',
        id: 'family_name',
        neto: 'BillingAddress.BillLastName'
    },
    'spouse-first-name': {
        type: 'text',
        label: 'Spouse First Name',
        source: 'contact',
        id: 'spouse_name'
    },
    'vip-membership-number': {
        type: 'text',
        label: 'VIP Membership Number',
        source: 'custom_field',
        id: 'VIP Membership Number'
    },
    'phone-1-type': {
        type: 'phone_type',
        label: 'Phone 1 Type',
        source: 'contact',
        id: 'phone_numbers[0].type'
    },
    'phone-1': {
        type: 'text',
        label: 'Phone 1',
        source: 'contact',
        id: 'phone_numbers[0].number',
        neto: 'BillingAddress.BillPhone'
    },
    'phone-2-type': {
        type: 'phone_type',
        label: 'Phone 2 Type',
        source: 'contact',
        id: 'phone_numbers[1].type'
    },
    'phone-2': {
        type: 'text',
        label: 'Phone 2',
        source: 'contact',
        id: 'phone_numbers[1].number',
        neto: 'ShippingAddress.ShipPhone'
    },
    'delivery-street-address-1': {
        type: 'text',
        label: 'Delivery Street Address 1',
        source: 'contact',
        id: 'addresses[0].line1',
        neto: 'BillingAddress.BillStreetLine1'
    },
    'delivery-street-address-2': {
        type: 'text',
        label: 'Delivery Street Address 2',
        source: 'contact',
        id: 'addresses[0].line2',
        neto: 'BillingAddress.BillStreetLine2'
    },
    'delivery-city': {
        type: 'text',
        label: 'Delivery City',
        source: 'contact',
        id: 'addresses[0].locality',
        neto: 'BillingAddress.BillCity'
    },
    'delivery-state': {
        type: 'select',
        label: 'Delivery State',
        source: 'contact',
        id: 'addresses[0].region',
        neto: 'BillingAddress.BillState',
        options: 'states'
    },
    'delivery-postal-code': {
        type: 'text',
        label: 'Delivery Postal Code',
        source: 'contact',
        id: 'addresses[0].postal_code',
        neto: 'BillingAddress.BillPostCode'
    },
    'expected-delivery-date': {
        type: 'date',
        label: 'Expected Delivery ',
        source: 'custom_field',
        id: 'Expected Delivery Date'
    },
    'append-to-notes': {
        type: 'textarea',
        label: 'Append to Person Notes',
        source: 'contact',
        id: 'notes',
        prefill: false
    },
    'interest': {
        type: 'select',
        label: 'Interest*',
        source: 'tags',
        required: true,
        id: 'INTEREST-',
        exclude: '-BRAND-',
        split: 'INTEREST-'
    },
    'brand': {
        type: 'select',
        label: 'Brand*',
        required: true,
        source: 'tags',
        id: 'INTEREST-BRAND-',
        include: '-BRAND-',
        split: 'BRAND-'
    },
};

let contactRequest = false;
const getKeapContact = async email => {
    if ( !email ) {
        return false;
    }

    if ( contactRequest ) {
        contactRequest.abort();
    }
    try {
        contactRequest = get('/api/keap/contacts?email=' + email);
        const existing = await contactRequest;
        return existing;

    } catch {}

}

const upsertCustomer = async (data, allTags, contactModel, isNewCustomer) => {
    console.log('Upserting cusomer', data);

    // Upsert in Infusionsoft
    const customer = {
        email_addresses: [
            {
              email: data['customer_email'],
              field: "EMAIL1"
            }
        ],
        addresses: [{
            field: 'SHIPPING',
            country_code: __KEAP_ENV__ === 'SWAU' ? 'AUS' : 'NZL'
        }],
        phone_numbers: [{
            field: 'PHONE1'
        }, {
            field: 'PHONE2'
        }],
        custom_fields: [],
        duplicate_option: 'Email'
    };
    const tags = [];

    Object.entries(fields).filter(([key, field]) => data[key]).map(([key, field]) => {

        if (field.source === 'contact') {
            setDescendantProp(customer, field.id, data[key]);
        }

        if (field.source === 'custom_field') {
            customer.custom_fields.push({
                id: Object.entries(contactModel.custom_fields).filter(([_, custom_field]) => custom_field.label === field.id)[0][1].id,
                content: data[key]
            })
        }
        if (field.source === 'tags') {
            tags.push(parseInt(data[key]));
        }
    });

    const removeTags = allTags.map(_ => _.id).filter(_ => tags.indexOf(_) === -1);

    console.log('Keap object', {customer, tags, removeTags});

    // Upsert in Neto (field mapping?)
    const neto = {
        'EmailAddress': data['customer_email']
    }

    Object.entries(fields).filter(([key, field]) => data[key]).map(([key, field]) => {
        if ( !field.neto ) {
            return;
        }

        setDescendantProp(neto, field.neto, data[key]);
    });

    const response = await post('/api/keap/upsert-contact', JSON.stringify({customer, tags, removeTags, neto, isNewCustomer}), );

    if ( !response.success ) {
        alert('Something went wrong saving this contact in Infusionsoft. Please try again later.')
    }

};

export default function CustomerForm({ cb }) {
    const [keapContact, setKeapContact] = useState();
    const [contactModel, setCustomerModel] = useState();
    const [keapUsers, setKeapUsers] = useState();
    const [keapTags, setKeapTags] = useState();

    const [loading, setLoading] = useState();
    const [submitting, setSubmitting] = useState();
    const [keapEmail, setKeapEmail] = useState();

    const { register, handleSubmit, watch, errors, control, setValue } = useForm({
        mode: 'onBlur'
    });

    useEffect(() => {

        getData();
        async function getData() {
            // Do the first one in sequence so auth tokens can be renewed
            await getContactModel();

            // These 2 can run in parallel
            getKeapUsers();
            getKeapTags();

        }

        async function getContactModel() {
            const model = await get('/api/keap/contact-model');
            setCustomerModel(model);
        }
        async function getKeapUsers() {
            const response = await get('/api/keap/users');
            setKeapUsers(response.users);
        }
        async function getKeapTags() {
            const response = await get('/api/keap/tags');

            const allTags = response.tags.map(tag => {
                const field = Object.entries(fields).reduce((out, [key, field]) => {
                    if (out) {
                        return out;
                    }

                    if (field.source !== 'tags') {
                        return false;
                    }

                    if ( !tag.name ) {
                        return false;
                    }

                    // Skip any tag with a space in it (invalid)
                    if ( tag.name.indexOf(' ') !== -1) {
                        return false;
                    }

                    // Make sure tag starts with the ENV name
                    if ( tag.name.indexOf(__KEAP_ENV__) !== 0 && !tag.name.match('^[0-9]{2}-')) {
                        return false;
                    }

                    // Make sure it contains the ID that we're looking for
                    if ( tag.name.indexOf(field.id) === -1 ) {
                        return false;
                    }

                    // Make sure required text is there
                    if (field.include && tag.name.indexOf(field.include) === -1) {
                        return false;
                    }

                    // Make sure excludes are taken into account
                    if (field.exclude && tag.name.indexOf(field.exclude) !== -1) {
                        return false;
                    }
                    return [key, field];

                }, false);

                return field ? {
                    id: tag.id,
                    field: field[0],
                    label: getTagLabel(tag, field[1])
                } : false;


            }).filter(_ => _);

            setKeapTags(getUnique(allTags, 'label'));
        }

    }, []);

    const on_update_email = async (email) => {

        // Unchanged?
        if (email === keapEmail) {
            return true;
        }

        setKeapEmail(email);
        setLoading(true);

        const contact = await getKeapContact(email);

        // Hack: Make sure the address is always the shipping address
        if (contact) {
            contact['addresses'] = contact['addresses'].filter(_ => _['field'] === 'SHIPPING')
        }

        setKeapContact(contact);
        setLoading(false);

        if (!contact) {
            return;
        }

        // Fill in form fields
        const customFields = {}
        Object.entries(contact.custom_fields).map(([k, field]) => customFields[field.id] = field.content||'' );

        Object.entries(fields).map(([key, field]) => {
            if (field.source === 'custom_field') {
                // Find custom field data
                const field_id = Object.entries(contactModel.custom_fields).filter(([_, custom_field]) => custom_field.label === field.id)[0][1].id
                setValue(key, customFields[field_id]);
            }
            if (field.source === 'contact') {
                if (field.options === 'states' ) {
                    // Correct ISO state names https://community.keap.com/t/invalid-country-code-and-region-what-is-valid/13354/10
                    const value = (getDescendantProp(contact, field.id)||'').toUpperCase();
                    if (value && Object.keys(states).indexOf(value) === -1) {

                        // Check if we need to prefix the state
                        const countryPrefix = 'SWAU' === __KEAP_ENV__ ? 'AU' : 'NZ';
                        if (Object.keys(states).indexOf(value) === -1 && Object.keys(states).indexOf(countryPrefix + value) > -1) {
                            setDescendantProp(contact, field.id, countryPrefix + value);

                        }

                        // Check if we need to replace the state by its key
                        const stateKeys = Object.entries(states).filter(([k, v]) => v.toUpperCase() === value.toUpperCase());
                        if (stateKeys) {
                            setDescendantProp(contact, field.id, stateKeys[0][0]);

                        }

                    }

                }

                if (field.prefill === false ) {
                    setValue(key, '');
                } else {
                    setValue(key, getDescendantProp(contact, field.id) );
                }

            }
            if (field.source === 'tags') {
                const fieldTags = keapTags.filter(_ => _.field === key).map(_ => _.id);

                // Intersect
                const intersect = fieldTags.filter(_ => contact.tag_ids.includes(_));
                if (intersect) {
                    setValue(key, intersect[0]);
                }

            }
        });

    }

    const labelWidth = 6;
    const inputWidth = 12 - labelWidth;

    const onSubmit = async data => {
        setSubmitting(true);

        const isNewCustomer = !keapContact;
        await upsertCustomer(data, keapTags, contactModel, isNewCustomer);
        setSubmitting(false);
        cb();
    };

    const getOptions = (key, field) => {

        if (field.source === 'custom_field') {
            const cf = Object.entries(contactModel.custom_fields).filter(([_, custom_field]) => custom_field.label === field.id)[0][1];
            return cf.options;
        }

        if (field.source === 'tags') {
            return keapTags.filter(_ => _.field === key);
        }

        if (field.options === 'states') {
            return Object.entries(states).map(([key, state]) => ({
                id: key,
                label: state
            }));
        }
    };

    return (!submitting) && contactModel && keapUsers && keapTags ? <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
            <Form.Label column sm={labelWidth}>Customer Email:</Form.Label>
            <Col sm={inputWidth}>
                <Form.Control
                    type="email"
                    name={"customer_email"}
                    placeholder="example@customer.com"
                    ref={register({
                        required: "Please enter the email address",
                        validate: on_update_email
                    })}
                    isInvalid={errors["customer_email"]}
                />
            </Col>
        </Form.Group>

        {(keapContact && <Alert variant='info'>
                    Updating existing contact.
                </Alert>)
                ||
                (loading && <Alert variant='light'>
                    <Spinner animation="grow" role="status" size='sm' style={{ marginRight: 10 }}>
                    </Spinner>
                    Fetching contact details...
                </Alert>)
                }

        {Object.entries(fields).map(([key, field]) => (
            <Form.Group as={Row} key={'field_' + key}>
                <Form.Label column sm={labelWidth}>{field.type !== 'checkbox' && field.label}</Form.Label>
                <Col sm={inputWidth}>

                    {(field.type === 'select' &&
                        <Form.Control
                            as="select"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading}
                            >
                            <option value=""></option>
                            {getOptions(key, field).map(option =>
                                    <option value={option.id} key={`f-${key}-${option.id}`}>{option.label}</option>
                                )
                            }
                        </Form.Control>)
                    ||
                    (field.type === 'checkbox' &&
                        <Form.Check
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading}
                            value={field.value}
                            label={field.label}
                            />)
                    ||
                    (field.type === 'phone_type' &&
                        <Form.Control
                            as="select"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading}
                            >
                            <option value=""></option>
                            {phone_types.map(_ => <option value={_} key={`ph-${key}-${_}`}>{_}</option>)}
                        </Form.Control>
                    )
                    ||
                    (field.type === 'user' &&
                        <Form.Control
                            as="select"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            isInvalid={errors[key]}
                            disabled={loading}
                            >
                            <option value=""></option>
                            {keapUsers.map(_ => <option value={_.id} key={`ku-${key}-${_.id}`}>{_.preferred_name}</option>)}
                        </Form.Control>
                    )
                    ||
                    (field.type === 'textarea' &&
                        <Form.Control
                            as="textarea"
                            name={key}
                            ref={register({
                                required: field.required
                            })}
                            row={3}
                            isInvalid={errors[key]}
                            disabled={loading}
                            />
                    )
                    ||
                    <Form.Control
                        type={field.type}
                        name={key}
                        placeholder=""
                        ref={register({
                            required: field.required
                        })}
                        isInvalid={errors[key]}
                        disabled={loading}
                        />
                    }
                </Col>
            </Form.Group>
        ))}


        <Button variant="primary" type="submit">
            {keapContact ? "Update Contact" : "Create Contact" }
        </Button>

    </Form> : <div>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner> {submitting && "Saving contact..."}
    </div>
}
